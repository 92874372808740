<template>
  <div>
    <h2>PAYMENT INQUIRY</h2>
    <img :src="stringImage" alt="Red dot" />
    <input type="file" id="file" @change="handleFile" />
    <button @click="clearInputFile()">Clear</button>

    <div id="canvas" style="display:none"></div>

    <div class="card mt-2 ">
      <div class="card-body">
        <div class="row">
          <div class="col text-start ">
            <span class="fw-bold">AREEMENT NO. : </span>
            {{ model.agreementNumber }}
          </div>
        </div>
        <div class="row">
          <div class="col text-start">
            <span class="fw-bold">ID CARD NO. : </span> {{ model.idCardNumber }}
          </div>
        </div>
        <div class="row">
          <div class="col text-start"><span class="fw-bold">ชื่อ : </span> {{ model.name }}</div>
          <div class="col text-start">
            <span class="fw-bold">สกุล : </span> {{ model.lastName }}
          </div>
        </div>
        <div class="row">
          <div class="col text-start">
            <span class="fw-bold">PRODUCT : </span> {{ model.product }}
          </div>
        </div>
        <div class="row">
          <div class="col text-start">
            <span class="fw-bold">PAYMENT TYPE : </span> {{ model.paymentType }}
          </div>
        </div>
      </div>
    </div>

    <table class="table table-bordered mt-5">
      <thead>
        <tr>
          <th>Page</th>
          <th>
            PAYMENT DATE
          </th>
          <th>AMOUNT</th>
          <th>USED</th>

          <th>TYPE</th>
          <th>COUNTER</th>
          <th>STATUS</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in model.rows" :key="index" :class="`color` + row.pageNumber">
          <td>{{ row.pageNumber }}</td>
          <td>{{ row.paymentDate }}</td>
          <td>{{ row.amount }}</td>
          <td>{{ row.used }}</td>
          <td>{{ row.type }}</td>
          <td>{{ row.counter }}</td>
          <td>{{ row.status }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style>
tr.color2,
tr.color4,
tr.color6,
tr.color8,
tr.color10,
tr.color12,
tr.color14,
tr.color16,
tr.color18,
tr.color20,
tr.color22 {
  background: rgba(0, 0, 0, 0.05);
}

#drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 480px;
  font-family: sans-serif;
  margin: 100px auto;
  padding: 20px;
}
#drop-area.highlight {
  border-color: purple;
}
p {
  margin-top: 0;
}
.my-form {
  margin-bottom: 10px;
}
#gallery {
  margin-top: 10px;
}
#gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}
.button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.button:hover {
  background: #ddd;
}
#fileElem {
  display: none;
}
</style>

<script>
import _ from "lodash";

import BaseConvert from "./convert-pdf-to-image.vue";
import utilsMixins from "../_mixins/utils-mixins";
export default {
  extends: BaseConvert,

  data() {
    return {
      model: {
        agreementNumber: "",
        idCardNumber: "",
        name: "",
        lastName: "",
        product: "",
        paymentType: "",
        rows: []
      },
      configProp: {
        paymentDate: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        amount: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        used: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        type: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        counter: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        },
        status: {
          headerX: {
            min: -1,
            max: -1,
            beginIndex: -1,
            endIndex: -1
          }
        }
      },

      currentRow: 0,
      stringImage: ""
    };
  },
  methods: {
    clearModel() {
      this.model = {
        agreementNumber: "",
        idCardNumber: "",
        name: "",
        lastName: "",
        product: "",
        paymentType: "",
        rows: []
      };

      this.currentRow = 0;
    },
    clearInputFile() {
      document.getElementById("file").value = null;
    },
    async handleFile(e) {
      let file = e.target.files[0];

      if (!file) {
        return;
      }

      this.convertToImagesBase64(file, results => {
        this.clearModel();

        results = _.orderBy(results, "pageNumber");
        let promises = results.map(x => this.callApi(x.result));
        Promise.all(promises).then((reses, index) => {
          reses.forEach((res, index) => {
            let pageNumber = index + 1;
            if (pageNumber == 1) {
              let valid = this.validateTypeFile(res);
              if (!valid) {
                alert("กรุณาเลือกไฟล์ PDF ประเภท PAYMENT INQUIRY เท่านั้น");
                return;
              }
            }
            //remove first order for full text
            res.responses[0].textAnnotations.splice(0, 1);

            if (pageNumber == 1) {
              this.findX(res);
              this.getHeader(res);
            }

            this.getRows(res, pageNumber);
          });
        });
      });
    },
    callApi(content) {
      let url =
        "https://vision.googleapis.com/v1/images:annotate?key=AIzaSyCv22vemzG4kY1ujcNMYCD0YKQGgAo3t7E";
      return this.$http.post(
        url,
        {
          requests: [
            {
              image: {
                content: `${content}`
              },
              features: [
                {
                  type: "TEXT_DETECTION"
                }
              ]
            }
          ]
        },
        { disabledBaseUrl: true }
      );
    },
    findX(res) {
      let arr = res.responses[0].textAnnotations;
      let gapXpx = 5;

      const findPaymentDate = () => {
        let xx = [];
        let keywords1 = ["PΑΥΜΕNT", "PAYMENT"];
        let keywords2 = ["DATE", "DATΕ"];

        var regex1 = new RegExp(keywords1.join("|"));
        var regex2 = new RegExp(keywords2.join("|"));

        arr.forEach((item, index) => {
          try {
            if (
              regex1.test(arr[index].description.toUpperCase()) &&
              regex2.test(arr[index + 1].description.toUpperCase())
            ) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));
              xx.push(...arr[index + 1].boundingPoly.vertices.map(x => x.x));

              this.configProp.paymentDate.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.paymentDate.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.paymentDate.headerX.beginIndex = index;
              this.configProp.paymentDate.headerX.endIndex = index + 1;
              return;
            }
          } catch (e) {}
        });
      };

      const findAmount = () => {
        let keywords1 = ["AMOUNT"];
        var regex1 = new RegExp(keywords1.join("|"));
        let beginIndex = this.configProp.paymentDate.headerX.endIndex + 1;

        let xx = [];

        arr.forEach((item, index) => {
          try {
            if (index >= beginIndex && regex1.test(item.description.toUpperCase())) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));

              this.configProp.amount.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.amount.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.amount.headerX.beginIndex = index;
              this.configProp.amount.headerX.endIndex = index;
              return;
            }
          } catch (e) {}
        });
      };

      const findUsed = () => {
        let keywords1 = ["USED"];
        var regex1 = new RegExp(keywords1.join("|"));
        let beginIndex = this.configProp.amount.headerX.endIndex + 1;

        let xx = [];
        arr.forEach((item, index) => {
          try {
            if (index >= beginIndex && regex1.test(item.description.toUpperCase())) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));

              this.configProp.used.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.used.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.used.headerX.beginIndex = index;
              this.configProp.used.headerX.endIndex = index;
              return;
            }
          } catch (e) {}
        });
      };

      const findType = () => {
        let keywords1 = ["TYPE"];
        var regex1 = new RegExp(keywords1.join("|"));
        let beginIndex = this.configProp.used.headerX.endIndex + 1;

        let xx = [];
        arr.forEach((item, index) => {
          try {
            if (index >= beginIndex && regex1.test(item.description.toUpperCase())) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));

              this.configProp.type.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.type.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.type.headerX.beginIndex = index;
              this.configProp.type.headerX.endIndex = index;
              return;
            }
          } catch (e) {}
        });
      };

      const findCounter = () => {
        let keywords1 = ["COUNTER"];
        var regex1 = new RegExp(keywords1.join("|"));
        let beginIndex = this.configProp.type.headerX.endIndex + 1;

        let xx = [];
        arr.forEach((item, index) => {
          try {
            if (index >= beginIndex && regex1.test(item.description.toUpperCase())) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));

              this.configProp.counter.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.counter.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.counter.headerX.beginIndex = index;
              this.configProp.counter.headerX.endIndex = index;
              return;
            }
          } catch (e) {}
        });
      };

      const findStatus = () => {
        let keywords1 = ["STATUS"];
        var regex1 = new RegExp(keywords1.join("|"));
        let beginIndex = this.configProp.counter.headerX.endIndex + 1;

        let xx = [];
        arr.forEach((item, index) => {
          try {
            if (index >= beginIndex && regex1.test(item.description.toUpperCase())) {
              xx.push(...arr[index].boundingPoly.vertices.map(x => x.x));

              this.configProp.status.headerX.min = Math.min(...xx) - gapXpx;
              this.configProp.status.headerX.max = Math.max(...xx) + gapXpx;
              this.configProp.status.headerX.beginIndex = index;
              this.configProp.status.headerX.endIndex = index;
              return;
            }
          } catch (e) {}
        });
      };

      findPaymentDate();
      findAmount();
      findUsed();
      findType();
      findCounter();
      findStatus();
    },
    findFocusIndex(arr, beginIndex) {
      if (!beginIndex) {
        beginIndex = 0;
      }

      let obj = {
        begin: -1,
        end: -1
      };

      let keywordsPayment = ["PAYMENT", "PAΥΜΕNT", "PΑΥΜΕNT"];
      var regex = new RegExp(keywordsPayment.join("|"));

      let indexFocus = arr.findIndex(
        (x, index) => index >= beginIndex && regex.test(x.description)
      );

      if (indexFocus >= 0) {
        let keywordsQuiry = ["QUIRY", "INQUIRY", "NQUIRY"];

        let condition2 = keywordsQuiry.includes(arr[indexFocus + 1].description);

        if (condition2 == true) {
          obj.begin = indexFocus;
          obj.end = indexFocus + 1;
        }
      }

      return obj;
    },
    validateTypeFile(res) {
      var obj = this.findFocusIndex(res.responses[0].textAnnotations, 1);
      let valid = obj.begin >= 0 ? true : false;

      return valid;
    },
    getHeader(res) {
      this.hAgreementNumber(res.responses[0].textAnnotations);
      this.hIdCardNo(res.responses[0].textAnnotations);
      this.hName(res.responses[0].textAnnotations);
      this.hProduct(res.responses[0].textAnnotations);
      this.hPaymentType(res.responses[0].textAnnotations);
    },
    hAgreementNumber(arr) {
      let objFocus = this.findFocusIndex(arr);
      let focusIndex = objFocus.end;
      let limit = 5;

      let keywords1 = ["AGREEMENT"];
      var regex1 = new RegExp(keywords1.join("|"));
      let keywords2 = ["NO"];
      var regex2 = new RegExp(keywords2.join("|"));

      for (let index = focusIndex; index < focusIndex + limit; index++) {
        if (
          regex1.test(arr[index].description.toUpperCase()) &&
          regex2.test(arr[index + 1].description.toUpperCase())
        ) {
          this.model.agreementNumber = arr[index + 3].description;
          break;
        }
      }
    },
    hIdCardNo(arr) {
      let objFocus = this.findFocusIndex(arr);
      let focusIndex = objFocus.end;
      let limit = 10;

      let keywords1 = ["ID"];
      var regex1 = new RegExp(keywords1.join("|"));
      let keywords2 = ["CARD"];
      var regex2 = new RegExp(keywords2.join("|"));
      let keywords3 = ["NO"];
      var regex3 = new RegExp(keywords3.join("|"));

      for (let index = focusIndex; index < focusIndex + limit; index++) {
        if (
          regex1.test(arr[index].description.toUpperCase()) &&
          regex2.test(arr[index + 1].description.toUpperCase()) &&
          regex3.test(arr[index + 2].description.toUpperCase())
        ) {
          this.model.idCardNumber = arr[index + 3].description;
          break;
        }
      }
    },
    hName(arr) {
      let objFocus = this.findFocusIndex(arr);
      let focusIndex = objFocus.end;
      let limit = 20;

      let keywords1 = ["NAME"];
      var regex1 = new RegExp(keywords1.join("|"));

      for (let index = focusIndex; index < focusIndex + limit; index++) {
        if (regex1.test(arr[index].description.toUpperCase())) {
          this.model.name = arr[index + 2].description;
          this.model.lastName = arr[index + 3].description;
          break;
        }
      }
    },
    hProduct(arr) {
      let objFocus = this.findFocusIndex(arr);
      let focusIndex = objFocus.end;
      let limit = 20;

      let keywords1 = ["PRODUCT"];
      var regex1 = new RegExp(keywords1.join("|"));
      for (let index = focusIndex; index < focusIndex + limit; index++) {
        if (regex1.test(arr[index].description.toUpperCase())) {
          let indexNext = arr
            .slice(index + 1)
            .findIndex(x => x.description.toUpperCase().includes("PΑΥΜΕNT"));

          let begin = index + 2;
          let end = begin + indexNext - 1;

          this.model.product = arr
            .slice(begin, end)
            .map(x => x.description)
            .join(" ");

          break;
        }
      }
    },
    hPaymentType(arr) {
      let objFocus = this.findFocusIndex(arr);
      let focusIndex = objFocus.end;
      let limit = 40;

      let keywords1 = ["PΑΥΜΕNT"];
      var regex1 = new RegExp(keywords1.join("|"));
      let keywords2 = ["ΤΥΡE", "TYPΕ"];
      var regex2 = new RegExp(keywords2.join("|"));
      for (let index = focusIndex; index < focusIndex + limit; index++) {
        if (
          regex1.test(arr[index].description.toUpperCase()) &&
          regex2.test(arr[index + 1].description.toUpperCase())
        ) {
          let begin = index + 3;

          this.model.paymentType = arr
            .slice(begin, this.configProp.paymentDate.headerX.beginIndex)
            .map(x => x.description)
            .join(" ");

          break;
        }
      }
    },
    getRows(res, pageNumber) {
      let gapYpx = 3;

      res.responses[0].textAnnotations
        .slice(this.configProp.status.headerX.endIndex + 1)
        .forEach((item, index) => {
          let xx = item.boundingPoly.vertices.map(x => x.x);
          let start = Math.min(...xx);
          let end = Math.max(...xx);
          // let positionUse = (start + end) / 2;
          let ymin = item.boundingPoly.vertices[0].y;

          if (
            this.configProp.paymentDate.headerX.min <= end &&
            this.configProp.paymentDate.headerX.max >= start
          ) {
            let row = {};
            row.pageNumber = pageNumber;
            for (let prop in this.configProp) {
              row[prop] = "";
            }

            let yy = [];
            yy.push(...item.boundingPoly.vertices.map(x => x.y));
            row.ymin = Math.min(...yy) - gapYpx;
            row.ymax = Math.max(...yy) + gapYpx;
            row.beginIndex = index;
            row.paymentDate = item.description;

            this.model.rows.push(row);
            this.currentRow++;
          } else {
            let row = this.model.rows.filter(
              x => x.ymin <= ymin && x.ymax >= ymin && x.pageNumber == pageNumber
            )[0];

            for (let prop in this.configProp) {
              if (prop != "paymentDate") {
                if (
                  this.configProp[prop].headerX.min <= end &&
                  this.configProp[prop].headerX.max >= start
                ) {
                  if (row) {
                    row[prop] += ` ${item.description}`;
                  }
                }
              }
            }
          }
        });

      let regexDate = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

      _.remove(this.model.rows, row => {
        return !regexDate.test(row.paymentDate);
      });
    }
  }
};
</script>
